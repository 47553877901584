import { Box, Button, useToast, Flex, Spinner } from "@chakra-ui/react";
import React, { useState } from "react";

import { FormInput, SelectInput } from "../../component/inputs/inputs";
import RequestFormContainer from "../../component/requestFormContainer";
import { useMutation } from "react-query";
import { generateRequestCode, veriflyeRequestCode } from "../../utils/fetch";
import { useNavigate } from "react-router-dom";

function RequestForm1() {
  const toast = useToast();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [occasion, setOccasion] = useState("");
  const [code, setCode] = useState("");
  const [id, setId] = useState("");

  const { mutate: mutates, isLoading } = useMutation(generateRequestCode, {
    onSuccess: (data) => {
      if (data?.data?.status === 200) {
        console.log(data);
        setShow(true);
        setId(data?.data?.id);
        toast({
          position: "top-right",
          description: "Code sent to your email.",
          status: "success",
        });
      }
    },
    onError: () => {},
  });
  const { mutate, isLoading: isLoadings } = useMutation(veriflyeRequestCode, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status === 200) {
        const form1 = {
          email: email,
          name: name,
          occasion: occasion,
          id: data?.data?.id,
        };
        localStorage.setItem("form1", JSON.stringify(form1));
        toast({
          position: "top-right",
          description: "Email Verified",
          status: "success",
        });
        navigate("/request2");
      } else {
        toast({
          position: "top-right",
          description: data?.data?.error,
          status: "error",
        });
      }
    },
    onError: () => {},
  });

  const submitHandler = (e) => {
    e.preventDefault();
    if (show) {
      mutate({ code: code, id: id });
    } else {
      mutates({ email: email });
    }
  };
  const sendCodeHandler = (e) => {
    mutates({ email: email });
  };

  const occationData = [
    "Birthday",
    "Anniversary",
    "Prayer",
    "Congratulations",
    "Other",
  ];
  return (
    <RequestFormContainer number={1}>
      <form onSubmit={submitHandler}>
        <SelectInput
          isRequired={true}
          options={occationData}
          label="Occasion"
          onChange={(e) => setOccasion(e.target.value)}
        />
        <Box display="flex">
          <FormInput
            isRequired={true}
            label="Email"
            type="email"
            mr="30px"
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormInput
            isRequired={true}
            label="Name"
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        {show ? (
          <>
            {" "}
            <FormInput
              label="Enter code"
              mr="30px"
              isRequired={true}
              onChange={(e) => setCode(e.target.value)}
            />
            <Button
              mt="-40px"
              fontWeight="100"
              fontSize="12px"
              ml="-13px"
              variant="transpare"
              onClick={sendCodeHandler}
            >
              Resend Code
            </Button>
          </>
        ) : (
          ""
        )}

        <Flex justifyContent="right">
          {show ? (
            <Button type="submit" variant="primary">
              {isLoadings ? (
                <Spinner color="white" thickness="4px" size="lg" />
              ) : (
                "Next"
              )}
            </Button>
          ) : (
            <Button type="submit" variant="primary">
              {isLoading ? (
                <Spinner color="white" thickness="4px" size="lg" />
              ) : (
                "Verify Email"
              )}
            </Button>
          )}
        </Flex>
      </form>
    </RequestFormContainer>
  );
}

export default RequestForm1;
