import { Box, Heading, Button, useToast, Spinner } from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormInput } from "../../component/inputs/inputs";
import * as Yup from "yup";
import { bookArtists } from "../../utils/fetch";
import { useMutation } from "react-query";

function SignUp() {
  const toast = useToast();

  const validationSchema = Yup.object().shape({
    artist_name: Yup.string().required("artist_name is required"),
    event_type: Yup.string().required("event_type is required"),
    venue: Yup.string().required("venue is required"),
    capacity: Yup.string().required("capacity is required"),
    date: Yup.string().required("date is required"),
    sponsors: Yup.string(),
    organizers: Yup.string().required("organizers is required"),
    organizers_address: Yup.string().required("organizers_address is required"),
    email: Yup.string().required("email is required").email(),
  });

  const { mutate, isLoading } = useMutation(bookArtists, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status === 200) {
        console.log(data);
        toast({
          position: "top-right",
          description: "Booking Sent",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        window.setTimeout(() => {
          window.location.href = `/`;
        }, 1000);
      } else {
        toast({
          position: "top-right",
          description: `${data?.data?.error || data?.data?.message}`,
          status: "error",
          duration: 9000,
        });
      }
    },
    onError: () => {},
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const registerArtist = (data) => {
    mutate(data);
  };

  return (
    <Box
    // w="100%"
    // backgroundImage="url('https://i.ibb.co/BjL2qM3/Group-8694.png')"
    // backgroundRepeat="no-repeat"
    // backgroundSize="auto"
    // backgroundPosition={"bottom right"}
    >
      <Box margin="auto" w={["90%", "90%", "40%", "40%"]} mt="10px">
        <Heading size="md" textAlign="center" pt="120px" pb="43px">
          Book your favorite Artist for private event or concerts
        </Heading>

        <form onSubmit={handleSubmit(registerArtist)}>
          <FormInput
            label="Artist Name"
            error={register("artist_name")}
            isInvalid={errors.artist_name && errors.artist_name.message}
          />
          <FormInput
            label="Type of Event"
            type={"event_type"}
            error={register("event_type")}
            isInvalid={errors.event_type && errors.event_type.message}
          />

          <FormInput
            label="Venue"
            error={register("venue")}
            isInvalid={errors.venue && errors.venue.message}
          />
          <Box display="flex" justifyContent="space-between">
            <FormInput
              label="Capacity"
              type="number"
              error={register("capacity")}
              isInvalid={errors.capacity && errors.capacity.message}
              mr="30px"
            />

            <FormInput
              error={register("date")}
              type="date"
              label="Date"
              isInvalid={errors.date && errors.date.message}
            />
          </Box>
          <FormInput
            label="Sponsors  or promoter (if any)"
            error={register("sponsors")}
            isInvalid={errors.sponsors && errors.sponsors.message}
          />

          <FormInput
            label="Organizer Name"
            error={register("organizers")}
            isInvalid={errors.organizers && errors.organizers.message}
          />

          <FormInput
            label="Organizer Address"
            error={register("organizers_address")}
            isInvalid={
              errors.organizers_address && errors.organizers_address.message
            }
          />
          <FormInput
            label="Organizer Email"
            error={register("email")}
            isInvalid={errors.email && errors.email.message}
          />
          <Button
            type="submit"
            isDisabled={isLoading}
            variant="primary"
            w="100%"
            mb="40px"
          >
            {isLoading ? (
              <Spinner color="white" thickness="4px" size="lg" />
            ) : (
              "Submit"
            )}
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default SignUp;
