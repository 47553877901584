import {
  Box,
  Button,
  Spacer,
  Flex,
  IconButton,
  Heading,
  useDisclosure,
  DrawerOverlay,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import React from "react";
import { home } from "../../images/svg";
import { Link, useNavigate } from "react-router-dom";
const NavBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const usertype = JSON.parse(localStorage.getItem("usertype"));
  const navigate = useNavigate();

  const logoutHandler = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  const menuData = [
    { name: "Book Artist", link: "book-artist" },
    { name: "Gallery", link: "gallery" },
    { name: "Artist", link: "artist" },
    { name: "Request Song", link: "request-song" },
    { name: "Watch", link: "watch" },
  ];
  return (
    <Box
      // bg={"RGBA(0, 0, 0, 0.64)"}
      position="absolute"
      color="white"
      width="100%"
      mb="50px"
    >
      <Box pl={6} pr={6} pt={5} pb={7}>
        <Flex>
          <Link to="/">
            <Heading size="md" mt="-13px">
              {home}
            </Heading>
          </Link>

          <Spacer />
          <Box
            display={["none", "none", "none", "flex", "flex", "flex"]}
            mt="10px"
          >
            {menuData?.map((data) => (
              <Link to={data?.link} key={data?.name}>
                <Button variant="transpare">{data?.name}</Button>
                <br></br>
              </Link>
            ))}
            {usertype === "admin" && (
              <Link to="/admin/home">
                <Button variant="transpare">DashBoard</Button>
                <br></br>
              </Link>
            )}
          </Box>
          <Spacer />
          {user_id ? (
            <Menu>
              <MenuButton
                as={Button}
                variant="primary"
                display={["none", "none", "none", "flex", "flex", "flex"]}
              >
                <HamburgerIcon />
              </MenuButton>

              <MenuList>
                <Link to={`/artist-details/${user_id}`}>
                  <MenuItem>Profile</MenuItem>
                </Link>

                <MenuItem onClick={logoutHandler}>Logout</MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <Box
              display={["none", "none", "none", "flex", "flex", "flex"]}
              mt="10px"
            >
              <Link to="login">
                <Button mr="40px" pr="35px" pl="35px" variant="primary">
                  Login
                </Button>
              </Link>
              <Link to="signup">
                <Button pr="20px" pl="20px" variant="secondary" color="primary">
                  Register
                </Button>
              </Link>
            </Box>
          )}

          <Box
            display={["flex", "flex", "flex", "none", "none", "none"]}
            mt="10px"
          >
            <Button
              as={IconButton}
              onClick={onOpen}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="primary"
            />
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
              size="xs"
            >
              <DrawerOverlay />
              <DrawerContent textAlign="left">
                <DrawerCloseButton />
                <Box mt="70px">
                  {menuData?.map((data) => (
                    <Box key={data}>
                      <Button
                        variant="transpare"
                        onClick={() => {
                          navigate(data?.link);
                          onClose(true);
                        }}
                      >
                        {data?.name}
                      </Button>
                      <br></br>
                    </Box>
                  ))}
                  {user_id ? (
                    <>
                      <Button
                        variant="transpare"
                        onClick={() => {
                          navigate(`/artist-details/${user_id}`);
                          onClose(true);
                        }}
                      >
                        Profile
                      </Button>
                      <br></br>

                      <Button onClick={logoutHandler} variant="transpare">
                        Logout
                      </Button>
                      <br></br>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="transpare"
                        onClick={() => {
                          navigate("/signup");
                          onClose(true);
                        }}
                      >
                        Get Started
                      </Button>

                      <br></br>

                      <Button
                        variant="transpare"
                        onClick={() => {
                          navigate("/login");
                          onClose(true);
                        }}
                      >
                        Login{" "}
                      </Button>

                      <br></br>
                    </>
                  )}
                </Box>
              </DrawerContent>
            </Drawer>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default NavBar;
