import { Text, Box, Heading, Avatar } from "@chakra-ui/react";
import small2 from "../../images/small2.png";
export const FirstSection = () => {
  const whatYouGet = [
    {
      pic: "https://i.ibb.co/MSW508p/Ellipse-2-2.jpg",
      text: "Access World-Class A&R and Management Services",
      top: "50px",
    },
    {
      pic: "https://i.ibb.co/mcjnqHf/Ellipse-2-1.jpg",
      text: "Access Funding",
    },
    {
      pic: small2,
      text: "Showcase your work to a Global Audience",
    },
    {
      pic: "https://i.ibb.co/56NVgN2/Ellipse-1.jpg",
      text: "Monetize your Work",
    },
    {
      pic: "https://i.ibb.co/BPs8BnS/Ellipse-1-1.jpg",
      text: "Access Top-Class Digital Distribution",
      down: "50px",
    },
  ];
  return (
    <Box bg="white" pb="50px">
      <Box width="90%" m="auto" pt="60px">
        <Heading color="black">What you get from Industry Nite</Heading>

        <Box
          display={["grid", "grid", "flex", "flex", "flex", "flex"]}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {whatYouGet?.map((data) => (
            <Box
              key={data?.text}
              border="1px solid black"
              m="10px"
              w={["90%", "90%", "220px", "220px"]}
              h="auto"
              p="30px"
              textAlign="center"
              bg="#1B1E32;"
              borderTopLeftRadius={data?.top}
              borderBottomRightRadius={data?.down}
            >
              <Avatar size="2xl" name="Dan Abrahmov" src={data?.pic} />
              <Text mt="25" fontSize="16px" color="white">
                <b>{data?.text}</b>
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
