import {
  Table,
  Thead,
  Tbody,
  Box,
  Tr,
  Th,
  Td,
  TableContainer,
  Skeleton,
  Image,
  SkeletonCircle,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getArtist } from "../../utils/fetch";
import Pagination from "../../component/pagenation";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Search2Icon } from "@chakra-ui/icons";

function AllArtistSong() {
  const { isLoading, data } = useQuery("get-artist", getArtist);

  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage] = useState(20);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(data?.data?.results?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.data?.results?.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, data]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % data?.data?.results?.length;

    setItemOffset(newOffset);
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    const newFilter = data?.data?.results?.filter((value) => {
      return value?.fullname.toLowerCase().includes(searchWord.toLowerCase());
    });
    console.log(filteredData);

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  return (
    <Box>
      <InputGroup w="350px" mt="10px" mb="30px">
        <InputLeftElement pointerEvents="none">
          <Search2Icon color="gray.300" />
        </InputLeftElement>
        <Input
          placeholder="Search"
          variant="outline"
          color="white"
          onChange={handleFilter}
        />
      </InputGroup>
      {filteredData.length >= 1 && (
        <Box
          mt="5px"
          w="350px"
          height="300px"
          position="absolute"
          boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
          overflowY="scroll"
          bg="linear-gradient(221.81deg, #1C1F33 15.38%, #0A0B15 84.11%)"
          border="1px solid white"
          zIndex="1000"
          css={{
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "teal",
              borderRadius: "24px",
            },
          }}
        >
          {filteredData &&
            filteredData.map((data) => (
              <Link
                key={data?.name}
                to={`/admin/all-artist-song-data/${data?.user_id}`}
                passHref
              >
                <Box
                  w="100%"
                  display="flex"
                  pl="20px"
                  pt="10px"
                  pb="10px"
                  _hover={{ bg: "teal", cursor: "pointer" }}
                  _expanded={{ bg: "teal" }}
                  _focus={{ boxShadow: "teal" }}
                >
                  {data?.fullname}
                </Box>
              </Link>
            ))}
        </Box>
      )}

      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Picture</Th>
              <Th>Fullname Name </Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <Tbody>
              {[2, 3]?.map((itemds) => (
                <Tr key={itemds}>
                  <Td>
                    <SkeletonCircle w="100px" h="100px" />
                  </Td>
                  <Td>
                    <Skeleton h="30px" />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Tbody>
              {currentItems?.reverse()?.map((itemds) => (
                <Tr key={itemds?.id}>
                  <Td>
                    <Link to={`/admin/all-artist-song-data/${itemds?.user_id}`}>
                      <Image
                        mt="20px"
                        borderRadius="50%"
                        minHeight="100px"
                        maxHeight="100px"
                        minWidth="100px"
                        maxWidth="100px"
                        src={itemds?.picture}
                        alt={itemds?.artist_name}
                      />
                    </Link>
                  </Td>
                  <Td>
                    <Link to={`/admin/all-artist-song-data/${itemds?.user_id}`}>
                      {itemds?.fullname}
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Pagination
        pageCount={isNaN(pageCount) ? 0 : pageCount}
        handlePageClick={handlePageClick}
      />
    </Box>
  );
}

export default AllArtistSong;
