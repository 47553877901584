import { Text, Box, Heading, IconButton, ButtonGroup } from "@chakra-ui/react";
import { home } from "../../images/svg";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
// const exploeData = [
//   "Album",
//   "Trending Tracks",
//   "PlayLists",
//   "Artist",
//   "Events",
// ];
// const discoverData = [
//   "Events",
//   "Shows",
//   "Concerts",
//   "Tours",
//   "Artiste",
//   "Charts",
// ];
// const LearnData = ["Join community", "Who we are"];

function Footer() {
  return (
    <Box>
      <Box
        bg="linear-gradient(221.81deg, #1C1F33 15.38%, #0A0B15 84.11%)"
        color="white"
      >
        <Box display={["grid", "grid", "flex", "flex"]} m="auto" w="90%">
          <Box w={["100%", "100%", "35%", "35%"]} mt="30px">
            {home}
            <Text mt="20px" pr="90px">
              Industry Nite is Nigeria’s foremost talent community and platform
              for upcoming and established creatives looking to connect with
              other talents in the industry while creating an unforgettable
              experience for the public.
            </Text>
            <Heading size="sm" mt="50px">
              Connect with us
            </Heading>
            <ButtonGroup variant="ghost" mb="30px">
              <IconButton
                as="a"
                variant="transpare"
                href=""
                aria-label="LinkedIn"
                icon={<BsTwitter fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                variant="transpare"
                href="#"
                aria-label="GitHub"
                icon={<BsFacebook fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                variant="transpare"
                href="https://www.instagram.com/industrynite.xyz"
                aria-label="GitHub"
                icon={<BsInstagram fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                variant="transpare"
                href="#"
                aria-label="Twitter"
                icon={<BsLinkedin fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                variant="transpare"
                href="https://youtube.com/@INDUSTRYNITE"
                aria-label="Twitter"
                icon={<BsYoutube fontSize="1.25rem" />}
              />
            </ButtonGroup>
          </Box>
        </Box>
        <hr
          style={{
            color: "white",
            margin: "auto",
            width: "90%",
            border: " 1.5px solid rgba(255, 255, 255, 0.26)",
          }}
        ></hr>
        <Text textAlign="center" mt="20px" pb="70px" fontSize="sm">
          ©2023 Industry Nite. All rights reserved
        </Text>
      </Box>
    </Box>
  );
}

export default Footer;
