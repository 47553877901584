import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import ReactPlayer from "react-player";

function Watch() {
  // const { isLoading, data } = useQuery("get-gallery", getGallery);
  // console.log(data?.data?.results);

  const eventss = [
    "https://youtu.be/KsdxCph0N_M",
    "https://www.youtube.com/watch?v=ODaAEiKnkZs",
    "https://www.youtube.com/watch?v=n6Ykvq-HtZA",
    "https://www.youtube.com/watch?v=y7CE5zIEVeo",
    "https://www.youtube.com/watch?v=pGKKWA2FcNU",
    "https://www.youtube.com/watch?v=A4allFrFcSk",
    "https://www.youtube.com/watch?v=ajls0gypePw",
    "https://www.youtube.com/watch?v=8k3837GJ81U",
    "https://www.youtube.com/watch?v=pIWILyFLCVc",
    "https://www.youtube.com/watch?v=G9B6CE3zYaU",
    "https://www.youtube.com/watch?v=N7OIdohVsQM",
    "https://www.youtube.com/watch?v=PXLdFBay1BE",
    "https://www.youtube.com/watch?v=X7v9RDvshEo",
    "https://www.youtube.com/watch?v=LnW8KFYr8GE",
    "https://www.youtube.com/watch?v=ODY6NXK3tYs",
    "https://www.youtube.com/watch?v=mMbnqjawcqU",
    "https://www.youtube.com/watch?v=4EC0eT9xFdU",
    "https://www.youtube.com/watch?v=sZsQolT17G8",
    "https://www.youtube.com/watch?v=CiWMhGwmp5U",
    "https://www.youtube.com/watch?v=BhNb1BCVB5I",
    "https://www.youtube.com/watch?v=sCL63kwNN3Y",
    "https://www.youtube.com/watch?v=rQ63Pv6w9w0",
    "https://www.youtube.com/watch?v=8eC-MwMaUxU",
    "https://www.youtube.com/watch?v=bSs5kCvhKts",
    "https://www.youtube.com/watch?v=_USTPWp_2a4",
    "https://www.youtube.com/watch?v=Ie410skUrEU",
    "https://www.youtube.com/watch?v=4xM4AFY2190",
    "https://www.youtube.com/watch?v=tUMUk6kcOro",
    "https://www.youtube.com/watch?v=JTaJWXeDzDk",
    "https://www.youtube.com/watch?v=CaaBD2eVBck",
    "https://www.youtube.com/watch?v=LLOeVYFin40",
    "https://www.youtube.com/watch?v=LUO5fa3e6dU",
    "https://www.youtube.com/watch?v=cLCPSS-hyy4",
    "https://www.youtube.com/watch?v=YXg3NMxHA_4",
    "https://www.youtube.com/watch?v=QDFwfkOdAlk",
    "https://www.youtube.com/watch?v=sCL63kwNN3Y",
    "https://www.youtube.com/watch?v=bVA2kVttmYo",
  ];
  return (
    <Box pt="130px">
      <Tabs variant="soft-rounded" onChange={(index) => console.log(index)}>
        <TabList justifyContent="center" fontSize="10px">
          <Tab
            _selected={{ color: "white", bg: "primary", border: "none" }}
            m="10px"
            color="white"
            fontSize={["10px", "10px", "15px", "15px"]}
            ml={["0px", "0px", "50px", "50px"]}
            border="1px solid white"
          >
            Past Event
          </Tab>
          <Tab
            _selected={{ color: "white", bg: "primary", border: "none" }}
            m="10px"
            color="white"
            fontSize={["10px", "10px", "15px", "15px"]}
            ml={["0px", "0px", "50px", "50px"]}
            border="1px solid white"
          >
            Interviews
          </Tab>
          <Tab
            _selected={{ color: "white", bg: "primary", border: "none" }}
            m="10px"
            color="white"
            fontSize={["10px", "10px", "15px", "15px"]}
            ml={["0px", "0px", "50px", "50px"]}
            border="1px solid white"
          >
            Latest
          </Tab>
          <Tab
            _selected={{ color: "white", bg: "primary", border: "none" }}
            m="10px"
            color="white"
            fontSize={["10px", "10px", "15px", "15px"]}
            ml={["0px", "0px", "50px", "50px"]}
            border="1px solid white"
          >
            Shows
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex
              flexWrap="wrap"
              justifyContent={[
                "center",
                "center",
                "space-between",
                "space-between",
              ]}
              w="95%"
              m="auto"
            >
              {eventss.map((data) => (
                <Box
                  _hover={{
                    transform: "scale(1.1)",
                  }}
                  mb="20px"
                  mt="20px"
                >
                  <ReactPlayer height="200px" width="300px" url={data} />
                </Box>
              ))}
            </Flex>
          </TabPanel>
          <TabPanel>
            <Heading textAlign="center" pt="100px" pb="500px">
              Coming Soon!
            </Heading>
          </TabPanel>
          <TabPanel>
            <Heading textAlign="center" pt="100px" pb="500px">
              Coming Soon
            </Heading>
          </TabPanel>
          <TabPanel>
            <Heading textAlign="center" pt="100px" pb="500px">
              Coming Soon
            </Heading>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Watch;
