import { Box, Heading, Button, useToast } from "@chakra-ui/react";
import { FormInput } from "../../component/inputs/inputs";
import { useState } from "react";
import { updatePassword } from "../../utils/fetch";
import { useMutation } from "react-query";

function UpdatePassword() {
  const toast = useToast();
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [pass, setPass] = useState("");
  const email = JSON.parse(localStorage.getItem("email"));
  const { mutate } = useMutation(updatePassword, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status === 200) {
        console.log(data);
        toast({
          position: "top-right",
          description: "Password Updated",
          status: "success",
        });
        window.localStorage.clear();
        window.location.href = "/login";
      } else {
        toast({
          position: "top-right",
          description: `${data?.data?.error}`,
          status: "error",
        });
      }
    },
    onError: () => {},
  });

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const datas = {
      old_password: oldPass,
      password: pass,
      confirm_password: newPass,
      email: email,
    };
    console.log(datas);
    mutate(datas);
  };

  return (
    <Box m="auto" w="40%" textAlign="center">
      <Heading size="md">Change Password</Heading>
      <form onSubmit={handlePasswordChange}>
        <FormInput
          isRequired={true}
          label="Current password"
          onChange={(e) => setOldPass(e.target.value)}
        />
        <FormInput
          isRequired={true}
          label="New password"
          onChange={(e) => setNewPass(e.target.value)}
        />
        <FormInput
          isRequired={true}
          label="Confirm password"
          onChange={(e) => setPass(e.target.value)}
        />
        <Button type="submit" variant="primary" w="100%">
          Change Password
        </Button>
      </form>
    </Box>
  );
}

export default UpdatePassword;
