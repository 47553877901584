import { Box, Heading, Skeleton } from "@chakra-ui/react";
import { getGallery } from "../../utils/fetch";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { HoverComponent } from "../../component/hoverComponent";

function Gallery() {
  const { isLoading, data } = useQuery("get-gallery", getGallery);
  console.log(data?.data?.results);

  return (
    <Box>
      <Box w="100%" p="50px"></Box>
      <Box pb="500px">
        <Heading size={["xl", "xl", "2xl", "2xl"]} p="50px">
          Gallery
        </Heading>
        <Box
          display={["grid", "grid", "flex", "flex"]}
          flexWrap="wrap"
          justifyContent={[
            "center",
            "center",
            "space-between",
            "space-between",
          ]}
          m="auto"
          w="90%"
        >
          {isLoading ? (
            <>
              <Skeleton mb="80px" w="350px" height="400px" />
              <Skeleton mb="80px" w="350px" height="400px" />
              <Skeleton mb="80px" w="350px" height="400px" />
            </>
          ) : (
            <>
              <Link to={`/gallery-details/${data?.data?.results[0]?.id}`}>
                <HoverComponent
                  city={data?.data?.results[0]?.category_name}
                  image="https://yt3.googleusercontent.com/2rv20jDUyG0YbISI0W639paAXXo9QkQP2n1N3RUNrnTWGoMyOkuzdDYTM0v0rdGQyEfuIY2WhJE=s900-c-k-c0x00ffffff-no-rj"
                  wid={["300px", "300px", "350px", "350px"]}
                  padder="33%"
                  height="300px"
                  m="40px"
                />
              </Link>
              <Link to={`/gallery-details/${data?.data?.results[1]?.id}`}>
                <HoverComponent
                  city={data?.data?.results[1]?.category_name}
                  image="https://guardian.ng/wp-content/uploads/2022/04/Pheelz-16-4-22-1424x802.jpg"
                  wid={["300px", "300px", "350px", "350px"]}
                  padder="33%"
                  height="300px"
                  m="40px"
                />
              </Link>
              <Link to={`/gallery-details/${data?.data?.results[2]?.id}`}>
                <HoverComponent
                  city={data?.data?.results[2]?.category_name}
                  image="https://cdn.smehost.net/rcarecordscom-usrcaprod/wp-content/uploads/2016/10/FRANKFIEBER_20181022_8-sm-scaled.jpg"
                  wid={["300px", "300px", "350px", "350px"]}
                  padder="33%"
                  height="300px"
                  m="40px"
                />
              </Link>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Gallery;
