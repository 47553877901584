import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
 
  
    @font-face{font-family:satoshi;font-style:normal;font-weight:200;src:local('Satoshi'),url(https://fonts.cdnfonts.com/s/85546/Satoshi-Regular.woff) format('woff')}
    
    
 
    
      `}
  />
);

export default Fonts;
