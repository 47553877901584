import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ModalBody } from "react-bootstrap";
import { getArtistSongDetails } from "../../utils/fetch";
import { FormInput } from "../../component/inputs/inputs";
import { addArtistLink } from "../../utils/fetch";
import { useState } from "react";
import ReactPlayer from "react-player";
import { AiFillInfoCircle } from "react-icons/ai";
function Artist() {
  let { user_id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [workLink, setWorkLink] = useState("");
  const [linkMusic, setLinkMusic] = useState(false);
  const { mutate, isLoading: addLinkLoader } = useMutation(addArtistLink, {
    onSuccess: (data) => {
      if (data?.data?.status === 200) {
        toast({
          position: "top-right",
          description: `${data?.data?.message}`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
      window.location.reload();
    },
    onError: (error) => {
      toast({
        position: "top-right",
        description: `${error?.response?.data?.msg}`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  const { isLoading, data, isError } = useQuery(
    ["get-artist-details", user_id],
    () => getArtistSongDetails(user_id)
  );
  if (
    (data?.data?.artist_info && data?.data?.artist_info.length < 1) ||
    isError
  ) {
    return (
      <Box h="100vh" display="flex" justifyContent="center">
        <Heading mt="45vh">Artist Not found</Heading>
      </Box>
    );
  }

  const user_ids = localStorage.getItem("user_id");

  const addWork = (e) => {
    e.preventDefault();
    setLinkMusic(true);
    const API_KEY =
      "5f630c3878fb4fcea6e022fbfb732a44xStUmGCMMNiELvqDUmZvYluhvHlQRW"; // API key here

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Basic " + btoa(API_KEY + ":"));

    var raw = JSON.stringify({ link: workLink });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api-us.musiio.com/api/v1/upload/audio-link", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.id) {
          setLinkMusic(false);
          const datas = { work_link: workLink, track_id: result?.id };
          console.log(datas);
          mutate(datas);
        } else {
          toast({
            position: "top-right",
            description: "please add a valid music link",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setLinkMusic(false);
        }
      });
  };
  console.log(data?.data?.artist_info);
  return (
    <Box bg="black">
      <Box pt="130px" w="90%" m="auto">
        <Button onClick={() => navigate(-1)} mb="40px" variant="transpare">
          Go Back
        </Button>

        <Box display="flex" justifyContent="center" flexWrap="wrap" mb="40px">
          {isLoading ? (
            <Skeleton
              w="300px"
              height="270px"
              mr={["0px", "0px", "20px", "20px", "100px", "100px"]}
            />
          ) : (
            <Image
              minHeight="270px"
              maxHeight="270px"
              minWidth="300px"
              maxWidth="300px"
              src={
                data?.data?.artist_info &&
                data?.data?.artist_info[0][0]?.picture
              }
              alt={
                data?.data?.artist_info &&
                data?.data?.artist_info[0][0]?.fullname
              }
              mr={["0px", "0px", "20px", "20px", "100px", "100px"]}
              borderRadius="10px"
            />
          )}

          <Box
            bg="#17191D"
            p={["30px", "30px", "10px", "30px", "30px", "30px"]}
            w={["90%", "90%", "350px", "400px", "400px", "400px"]}
            mt={["40px", "40px", "0px", "0", "0", "0"]}
          >
            <Text display="flex" mt="15px" mb="15px" pr="150x">
              <Box w="40%" mr="15px">
                Fullname:
              </Box>
              {isLoading ? (
                <Skeleton w="90%" height="20px" />
              ) : (
                data?.data?.artist_info &&
                data?.data?.artist_info[0][0]?.fullname
              )}
            </Text>
            <Text display="flex" mt="15px" mb="15px" pr="">
              <Box w="40%" mr="15px">
                City:
              </Box>
              {isLoading ? (
                <Skeleton w="90%" height="20px" />
              ) : (
                data?.data?.artist_info && data?.data?.artist_info[0][0]?.city
              )}
            </Text>
            <Text display="flex" mt="15px" mb="15px" pr="">
              <Box w="40%" mr="15px">
                Artistry:
              </Box>
              {isLoading ? (
                <Skeleton w="90%" height="20px" />
              ) : (
                data?.data?.artist_info &&
                data?.data?.artist_info[0][0]?.user_category
              )}
            </Text>
            <Text display="flex" mt="15px" mb="15px" pr="">
              <Box w="40%" mr="15px">
                Age:
              </Box>
              {isLoading ? (
                <Skeleton w="90%" height="20px" />
              ) : (
                data?.data?.artist_info && data?.data?.artist_info[0][0]?.age
              )}
            </Text>
            <Text display="flex" mt="15px" mb="15px" pr="">
              <Box w="40%" mr="15px">
                Instagram:
              </Box>
              {isLoading ? (
                <Skeleton w="90%" height="20px" />
              ) : (
                data?.data?.artist_info &&
                data?.data?.artist_info[0][0]?.instagram
              )}
            </Text>
          </Box>
        </Box>
        <Heading size="lg" mb="30px">
          Bio
        </Heading>
        <Text display="flex" w={["100%", "100%", "60%", "60%", "60%", "60%"]}>
          {data?.data?.artist_info && data?.data?.artist_info[0][0]?.biodata}
        </Text>
        <Heading mt="130px" size="lg">
          <Tooltip
            hasArrow
            label="Please Login to Add Link"
            bg="gray.300"
            color="black"
          >
            Link to Artist work
          </Tooltip>
        </Heading>
        {data?.data?.artist_info[1].length === 0 && (
          <Text mt="30px">No Work Link Added Yet</Text>
        )}
        {user_id === user_ids ? (
          <Box display="flex" justifyContent="right">
            <Button variant="secondary" onClick={onOpen}>
              Add work link{" "}
            </Button>
          </Box>
        ) : (
          ""
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />

            <ModalBody>
              <Box
                backgroundImage="https://i.ibb.co/6gJMmjT/Frame-8650.jpg"
                height="auto"
                backgroundRepeat="no-repeat"
                backgroundSize="auto"
                backgroundPosition="center"
                textAlign="center"
                fontSize="18px"
                p="60px"
              >
                Submit your work links for producer as well as fans to find and
                listen to your music
              </Box>
              <Box w="80%" m="auto" mb="40px" mt="10px">
                <form onSubmit={addWork}>
                  <Flex justifyContent="space-between">
                    <FormInput
                      type="url"
                      label="Work Link"
                      isRequired={true}
                      onChange={(e) => setWorkLink(e.target.value)}
                    />
                    <Tooltip
                      label="Only YouTube and SoundCloud Links Are Accepted"
                      aria-label="A tooltip"
                    >
                      <Box mt="30px" ml="-40px" zIndex="10000">
                        <AiFillInfoCircle size="25px" />
                      </Box>
                    </Tooltip>
                  </Flex>

                  <Button variant="primary" type="submit" width="100%">
                    {linkMusic || addLinkLoader ? (
                      <Spinner color="white" thickness="4px" size="lg" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </form>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Flex
          display={["flex", "flex", "grid", "grid", "grid", "grid"]}
          flexWrap={["wrap", "wrap", "", "", "", ""]}
          gridTemplateColumns={[
            "",
            "",
            "repeat(auto-fill, 280px)",
            "repeat(auto-fill, 280px)",
            "repeat(auto-fill, 280px)",
            "repeat(auto-fill, 280px)",
          ]}
          justifyContent={[
            "center",
            "center",
            "space-between",
            "space-between",
            "space-between",
            "space-between",
          ]}
          mt="20px"
          pb="50px"
        >
          {data?.data?.artist_info &&
            data?.data?.artist_info[1].map((data, i) => (
              <Box mb="50px">
                <ReactPlayer
                  controls={true}
                  url={data?.link}
                  height="auto"
                  width="300px"
                />
              </Box>
            ))}
        </Flex>
      </Box>
    </Box>
  );
}

export default Artist;
//https://24naijamusicfiles.com/wp-content/uploads/2021/07/Backstreet_Boys_-_I_Want_It_That_Way.mp3
