import NavBar from "./navbar";
import Footer from "./footer";
import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../../utils/scrollToTop";

export const Layout = () => {
  //"#b4816b"
  return (
    <>
      <ScrollToTop />
      <NavBar />

      <Outlet />
      <Footer />
    </>
  );
};
