import {
  Input,
  FormControl,
  Textarea,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import { FormLabel } from "react-bootstrap";

export const FormInput = ({
  onChange,
  label,
  type,
  mr,
  id,
  isInvalid,
  error,
  min,
  isRequired,
}) => {
  return (
    <FormControl textAlign="left" isInvalid={isInvalid} mr={mr} mb="30px">
      <FormLabel>{label}</FormLabel>
      <br></br>
      <Input
        {...error}
        isRequired={isRequired}
        type={type}
        variant="filled"
        w="100%"
        onChange={onChange}
      />
      <FormErrorMessage>{isInvalid}</FormErrorMessage>
    </FormControl>
  );
};
export const TextareaInput = ({
  label,
  mr,
  id,
  isRequired,
  onChange,
  isInvalid,
  error,
  placeholder,
}) => {
  return (
    <FormControl textAlign="left" isInvalid={isInvalid} mb="30px" mt="30px">
      <FormLabel>{label}</FormLabel>
      <br></br>
      <Textarea
        {...error}
        isRequired={isRequired}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
        variant="filled"
        w="100%"
        h="150px"
      />
      <FormErrorMessage>{isInvalid}</FormErrorMessage>
    </FormControl>
  );
};
export const SelectInput = ({
  label,
  isInvalid,
  error,
  isRequired,
  options,
  mr,
  onChange,
}) => {
  return (
    <FormControl textAlign="left" mb="30px" mr={mr}>
      <FormLabel>{label}</FormLabel>
      <br></br>
      <Select
        {...error}
        size="md"
        variant="filled"
        isRequired={isRequired}
        onChange={onChange}
      >
        <option value="">Select option</option>
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      <p style={{ color: "#FC8181", fontSize: "14px" }}>{isInvalid}</p>
    </FormControl>
  );
};
