import { Box, Button, useToast, Flex } from "@chakra-ui/react";
import React, { useState } from "react";

import { SelectInput, TextareaInput } from "../../component/inputs/inputs";
import RequestFormContainer from "../../component/requestFormContainer";
import { useNavigate } from "react-router-dom";

function RequestForm2() {
  const toast = useToast();
  const navigate = useNavigate();
  const [genre, setGenre] = useState("");
  const [tempo, setTempo] = useState("");
  const [length, setLength] = useState("");
  const [songInfo, setSongInfo] = useState("");
  const [mood, setMood] = useState("");

  const genreData = ["R&B", "Rap/Hip Hop", "Afrobeats", "Jazz", "Reggae"];
  const tempoData = ["Medium", "Slow", "Uptempo", "No Preference"];
  const moodData = [
    "HeartFelt",
    "Romantic",
    "Reflective",
    "Uplifting",
    "Happy",
    "Somber",
  ];
  const songData = [
    { name: "1min", values: 1 },
    { name: "1min 30secs", values: 1.5 },
    { name: "2min", values: 2 },
    { name: "2mins 30secs", values: 2.5 },
    { name: "3mins", values: 3 },
  ];
  const submitHandler = (e) => {
    e.preventDefault();
    if (length) {
      const form2 = {
        genre: genre,
        tempo: tempo,
        length: length,
        songInfo: songInfo,
        mood: mood,
      };
      localStorage.setItem("form2", JSON.stringify(form2));
      navigate("/request3");
    } else {
      toast({
        position: "top-right",
        description: "Please select the song length",
        status: "error",
      });
    }
  };
  return (
    <RequestFormContainer number={1}>
      <form onSubmit={submitHandler}>
        <Box display="flex" mb="20px">
          <SelectInput
            isRequired={true}
            options={genreData}
            label="Genre"
            mr="30px"
            onChange={(e) => setGenre(e.target.value)}
          />
          <SelectInput
            isRequired={true}
            options={tempoData}
            label="Tempo"
            onChange={(e) => setTempo(e.target.value)}
          />
        </Box>
        <SelectInput
          isRequired={true}
          options={moodData}
          label="Mood"
          onChange={(e) => setMood(e.target.value)}
        />
        <div className="cat">
          <div>Song Length:</div>
          {songData?.map((items) => (
            <label key={items?.values}>
              <input
                type="radio"
                name="songLength"
                value={items?.values}
                onChange={(e) => setLength(e.target.value)}
              ></input>
              <span>{items?.name}</span>
            </label>
          ))}
        </div>
        <TextareaInput
          isRequired={true}
          label="What story will your song tell?"
          onChange={(e) => setSongInfo(e.target.value)}
          placeholder="Provide artist will all the inspiration they will use to craft your song"
        />
        <Flex justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="secondary">
            Back
          </Button>
          <Button type="submit" variant="primary">
            Next
          </Button>
        </Flex>
      </form>
    </RequestFormContainer>
  );
}

export default RequestForm2;
