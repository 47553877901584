export const data = {
  title: "Frequently asked questions",
  rows: [
    {
      title: "What are they?",
      content: `Custom songs are songs requested by our music lovers to be written and recorded by our featured Industry Nite artists, each custom song is an original personalized story to you. Our artists will maintain a standard song structure of 1:00 - 2:30 minutes in length which includes 2 versus and a chorus. The songs created by our artists are 100% owned by them, and we do not grant song ownership to anyone outside of the artist that created it.`,
    },
    {
      title: "How do I put in a song request?",
      content: `Click on the link and it will take you to a form where you can fill out details about your custom song.`,
    },
    {
      title: "How long does it take to deliver my song?",
      content:
        "Once your song is ready, you will get an email with the URL to the song for you to listen to. The song will be ready in 4-7 business days??",
    },
    {
      title: "How will i receive my song?",
      content: `On your song page you can listen to the song, view the lyrics and learn more about the artist who did your song. You can also share the link to your friends and family.`,
    },
    {
      title: "How do you create my custom song?",
      content: `Each song is created 100 percent based on the submission you have provided. Instrumentals and melodies may be re-purposed by same artists across different songs based on customers request to ensure best possible song match.`,
    },
  ],
};
