import {
  Box,
  Heading,
  Button,
  Text,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { completeSongRequest, subbmitSongRequest } from "../../utils/fetch";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../component/inputs/inputs";
import { useState } from "react";

function SongRequestSpec() {
  const toast = useToast();
  const navigate = useNavigate();
  const [submitLink, setSubmitLink] = useState("");

  let { id } = useParams();

  const { data } = useQuery(["complete-song-request", id], () =>
    completeSongRequest(id)
  );

  const { mutate } = useMutation(subbmitSongRequest, {
    onSuccess: (data) => {
      if (data?.data?.status === 200) {
        console.log(data);
        toast({
          position: "top-right",
          description: "Link Sent",
          status: "success",
        });
        navigate("/admin/delivered-songs");
      }
    },
    onError: () => {},
  });
  console.log(data);
  const handleSubmitLink = (e) => {
    e.preventDefault();
    const datas = { music_link: submitLink, id: id };
    console.log(datas);
    mutate(datas);
  };

  const summaryContent = [
    { name: "Occasion", content: data?.data?.result[0]?.ocassion },
    { name: "Name", content: data?.data?.result[0]?.name },
    { name: "Email", content: data?.data?.result[0]?.email },
    { name: "Artist Name", content: data?.data?.result[0]?.artist_name },
    { name: "Genre", content: data?.data?.result[0]?.genre },
    { name: "Tempo", content: data?.data?.result[0]?.tempo },
    { name: "Mood", content: data?.data?.result[0]?.mood },
    {
      name: "Song length",
      content: `${data?.data?.result[0]?.song_length}Min`,
    },
  ];

  return (
    <>
      <Button onClick={() => navigate(-1)} mb="40px" variant="secondary">
        Go Back
      </Button>
      <Box
        w={["95%", "95%", "70%", "70%"]}
        m="auto"
        p={["10px", "10px", "80px", "80px"]}
        bg="#2D3748"
        borderRadius="10px"
        mt="50px"
        mb="30px"
      >
        <Heading size="md" textAlign="center" mt="20px" mb="20px">
          Request Details
        </Heading>
        <Box m="auto" w="80%">
          {summaryContent?.map((items) => (
            <Box key={items?.name}>
              <Box display="flex" justifyContent="space-between">
                <Text>{items?.name}</Text>
                <Text>{items?.content}</Text>
              </Box>
              <Divider border="1px solid" mt="10px" mb="30px" />
            </Box>
          ))}
          <Text>
            Story
            <br></br>
            <br></br>
            {data?.data?.result[0]?.song_story}
          </Text>
          <Box w="100%" mt="70px">
            <form onSubmit={handleSubmitLink}>
              <FormInput
                label="Song Link"
                mr="30px"
                isRequired={true}
                onChange={(e) => setSubmitLink(e.target.value)}
              />
              <Button w="100%" type="submit" variant="primary">
                Submit
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SongRequestSpec;
