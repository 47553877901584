import axios from "axios";
import { BaseURL } from "./url";

export const getArtist = () => {
  return axios.get(`${BaseURL}/get-artists`);
};
export const getFeaturedArtist = () => {
  return axios.get(`${BaseURL}/get-featured-artists`);
};
export const getCutomizedFeaturedArtist = (genre, length) => {
  return axios.get(
    `${BaseURL}/get-cutomized-song-artists/?genre=${genre}&length=${length}`
  );
};
export const getArtistDetails = async (user_id) => {
  return axios.get(`${BaseURL}/get-artists-profile?user_id=${user_id}`);
};
export const getArtistSongDetails = async (user_id) => {
  return axios.get(`${BaseURL}/get-music-analysis?user_id=${user_id}`);
};
export const getGalaryDetails = async (id) => {
  return axios.get(`${BaseURL}/get-gallery-pictures/${id}`);
};
export const getGallery = async () => {
  return axios.get(`${BaseURL}/get-gallery-category`);
};
export const getAllSongRequest = async () => {
  return axios.get(`${BaseURL}/song-request`);
};
export const getalltest = async () => {
  return axios.get(`${BaseURL}/check-link`);
};
export const completeSongRequest = async (id) => {
  return axios.get(`${BaseURL}/song-request-details/${id}`);
};
export const subbmitSongRequest = async (datas) => {
  return axios.post(`${BaseURL}/complete-song-request/${datas?.id}`, datas);
};
export const updatePassword = async (datas) => {
  return axios.post(`${BaseURL}/update-password/${datas?.email}`, datas);
};
export const registerUser = async (formData) => {
  return axios.post(`${BaseURL}/upload222-update`, formData);
};
export const bookArtists = async (data) => {
  return axios.post(`${BaseURL}/artist-request`, data);
};
export const loginUser = async (data) => {
  return axios.post(`${BaseURL}/login`, data);
};
export const createCustonSong = async (data) => {
  return axios.post(`${BaseURL}/custom-song-request`, data);
};
export const addArtistLink = async (datas) => {
  return axios.post(`${BaseURL}/add-work-link-update`, datas);
};
export const generateRequestCode = async (data) => {
  return axios.post(`${BaseURL}/generate-secret-code`, data);
};
export const veriflyeRequestCode = async (data) => {
  return axios.post(`${BaseURL}/verify/${data?.id}`, { secret: data?.code });
};
