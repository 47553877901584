import {
  Table,
  Thead,
  Tbody,
  Box,
  Tr,
  Th,
  Td,
  TableContainer,
  Skeleton,
  Button,
  Heading,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getArtistSongDetails } from "../../utils/fetch";
import Pagination from "../../component/pagenation";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function AllArtistSongList() {
  let { user_id } = useParams();
  let navigate = useNavigate();
  const { isLoading, data } = useQuery(["get-artist-song", user_id], () =>
    getArtistSongDetails(user_id)
  );
  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(data?.data?.artist_info[1]?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.data?.artist_info[1]?.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, data]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % data?.data?.artist_info[1]?.length;

    setItemOffset(newOffset);
  };

  return (
    <Box>
      <Button onClick={() => navigate(-1)} mb="40px" variant="transpare">
        Go Back
      </Button>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>link</Th>
              <Th>Autotune Presence</Th>
              <Th>Autotune Presence Score</Th>
              <Th>bpm</Th>
              <Th>bpm score </Th>
              <Th>bpm variation</Th>
              <Th>bpm variation score</Th>
              <Th>content type v2</Th>
              <Th>content type v2 score</Th>
              <Th>energy</Th>
              <Th>energy score</Th>
              <Th>energy variation</Th>
              <Th>energy variation score</Th>
              <Th>genre</Th>
              <Th>genre score</Th>
              <Th>genre secondary</Th>
              <Th>genre secondary score</Th>
              <Th>genre v2</Th>
              <Th>genre v2 score</Th>
              <Th>genre v3</Th>
              <Th>genre v3 score</Th>
              <Th>instrument</Th>
              <Th>instrument score</Th>
              <Th>instrument v3</Th>
              <Th>instrument v3 score</Th>
              <Th>instrumentation</Th>
              <Th>instrumentation score</Th>
              <Th>key flat</Th>
              <Th>key flat score</Th>
              <Th> key flat secondary</Th>
              <Th>key flat secondary score</Th>
              <Th>key secondary</Th>
              <Th>key secondary score</Th>
              <Th>key sharp</Th>
              <Th>key sharp score</Th>
              <Th>key sharp secondary</Th>
              <Th>key sharp secondary score</Th>
              <Th>keyy</Th>
              <Th>keyy score</Th>

              <Th>mood</Th>
              <Th>mood score</Th>
              <Th>mood secondary</Th>
              <Th>mood secondary score</Th>
              <Th>mood v3</Th>
              <Th>mood v3 score</Th>
              <Th>mood valence</Th>
              <Th>mood valence score</Th>
              <Th>quality</Th>
              <Th>quality score</Th>
              <Th>scale</Th>
              <Th>scale score</Th>
              <Th>use case</Th>
              <Th>use case score</Th>
              <Th>vocal gender</Th>
              <Th>vocal gender score</Th>
              <Th>vocal presence</Th>
              <Th>vocal presence score</Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <Tbody>
              {[2, 3, 4, 5, 6, 7, 8, 99, 0]?.map((itemds) => (
                <Tr key={itemds}>
                  {[2, 3, 4, 5, 6, 7, 8, 99, 0]?.map((itemds) => (
                    <Td key={itemds}>
                      <Skeleton h="30px" />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Tbody>
              {currentItems?.reverse()?.map((itemds) => (
                <Tr key={itemds?.autotune_presence}>
                  <Td>
                    <Link to={`${itemds?.link}`}> {itemds?.link}</Link>{" "}
                  </Td>
                  <Td>{itemds?.autotune_presence}</Td>
                  <Td>{itemds?.autotune_presence_score}</Td>
                  <Td>{itemds?.bpm}</Td>
                  <Td>{itemds?.bpm_score}</Td>
                  <Td>{itemds?.bpm_variation}</Td>
                  <Td>{itemds?.bpm_variation_score}</Td>
                  <Td>{itemds?.content_type_v2}</Td>
                  <Td>{itemds?.content_type_v2_score}</Td>
                  <Td>{itemds?.energy}</Td>
                  <Td>{itemds?.energy_score}</Td>
                  <Td>{itemds?.energy_variation}</Td>
                  <Td>{itemds?.energy_variation_score}</Td>
                  <Td>{itemds?.genre}</Td>
                  <Td>{itemds?.genre_score}</Td>
                  <Td>{itemds?.genre_secondary}</Td>
                  <Td>{itemds?.genre_secondary_score}</Td>
                  <Td>{itemds?.genre_v2}</Td>
                  <Td>{itemds?.genre_v2_score}</Td>
                  <Td>{itemds?.genre_v3}</Td>
                  <Td>{itemds?.genre_v3_score}</Td>
                  <Td>{itemds?.instrument}</Td>
                  <Td>{itemds?.instrument_score}</Td>
                  <Td>{itemds?.instrument_v3}</Td>
                  <Td>{itemds?.instrument_v3_score}</Td>
                  <Td>{itemds?.instrumentation}</Td>
                  <Td>{itemds?.instrumentation_score}</Td>
                  <Td>{itemds?.key_flat}</Td>
                  <Td>{itemds?.key_flat_score}</Td>
                  <Td>{itemds?.key_flat_secondary}</Td>
                  <Td>{itemds?.key_flat_secondary_score}</Td>
                  <Td>{itemds?.key_secondary}</Td>
                  <Td>{itemds?.key_secondary_score}</Td>
                  <Td>{itemds?.key_sharp}</Td>
                  <Td>{itemds?.key_sharp_score}</Td>
                  <Td>{itemds?.key_sharp_secondary}</Td>
                  <Td>{itemds?.key_sharp_secondary_score}</Td>
                  <Td>{itemds?.keyy}</Td>
                  <Td>{itemds?.keyy_score}</Td>

                  <Td>{itemds?.mood}</Td>
                  <Td>{itemds?.mood_score}</Td>
                  <Td>{itemds?.mood_secondary}</Td>
                  <Td>{itemds?.mood_secondary_score}</Td>
                  <Td>{itemds?.mood_v3}</Td>
                  <Td>{itemds?.mood_v3_score}</Td>
                  <Td>{itemds?.mood_valence}</Td>
                  <Td>{itemds?.mood_valence_score}</Td>
                  <Td>{itemds?.quality}</Td>
                  <Td>{itemds?.quality_score}</Td>
                  <Td>{itemds?.scale}</Td>
                  <Td>{itemds?.scale_score}</Td>
                  <Td>{itemds?.use_case}</Td>
                  <Td>{itemds?.use_case_score}</Td>
                  <Td>{itemds?.vocal_gender}</Td>
                  <Td>{itemds?.vocal_gender_score}</Td>
                  <Td>{itemds?.vocal_presence}</Td>
                  <Td>{itemds?.vocal_presence_score}</Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
        {!data?.data?.artist_info[1]?.length >= 1 && (
          <Heading mt="200px" mb="200px" textAlign="center" size="md">
            No Work Link added
          </Heading>
        )}
      </TableContainer>
      <Pagination
        pageCount={isNaN(pageCount) ? 0 : pageCount}
        handlePageClick={handlePageClick}
      />
    </Box>
  );
}

export default AllArtistSongList;
