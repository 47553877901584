import {
  Text,
  Button,
  Box,
  Heading,
  Image,
  SkeletonCircle,
  // Flex,
} from "@chakra-ui/react";
import { FirstSection } from "./firstSection";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getFeaturedArtist } from "../../utils/fetch";
import { useQuery } from "react-query";
import generous from "../../images/GenerousActivism_Landscape.png";
import { useRef } from "react";
//import ReactPlayer from "react-player";

function Home({ props }) {
  const containerRef = useRef(null);
  const { isLoading, data } = useQuery(
    "get-featured-artist",
    getFeaturedArtist
  );
  useEffect(() => {
    const container = containerRef.current;
    let scrollLeft = 0;
    const intervalId = setInterval(() => {
      scrollLeft += 1.4;
      if (scrollLeft >= container.scrollWidth - container.clientWidth) {
        scrollLeft = 0;
      }
      container.scrollLeft = scrollLeft;
    }, 20);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <React.Fragment>
      <Box
        pt="90px"
        height="100vh"
        backgroundImage="url('./images/firstBgImg.png')"
        backgroundRepeat="no-repeat"
        backgroundSize="auto"
        backgroundPosition={[
          "bottom right",
          "bottom right",
          "center",
          "center",
        ]}
      >
        <Box mt="120px" ml={["30px", "30px", "60px", "60px"]}>
          <Heading size={["xl", "2xl", "2xl", "2xl"]} color="white">
            Join Nigeria's Thriving <br></br>Music Community with <br></br>
            Industry Nite
          </Heading>
          <Text color="gray" mt="40px" mb="20px">
            Connect with Artists and Fans Today!
          </Text>
        </Box>
      </Box>
      <FirstSection />

      <Box
        backgroundImage="url('https://i.ibb.co/Sf0cTXZ/Rectangle-11.jpg')"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize={["auto", "auto", "auto", "contain"]}
        h="500px"
        mt="-10px"
      >
        <Heading
          size={["xl", "xl", "3xl", "3xl"]}
          textAlign="center"
          pt={["130px", "130px", "200px", "200px"]}
          w="80%"
          m="auto"
        >
          Are you an artiste? Showcase your talent to a global audience<br></br>
          <Link to="signup" textAlign="center">
            <Button mr="60px" variant="primary" mt="20px">
              Get Started
            </Button>
          </Link>
        </Heading>
      </Box>

      <Box w="90%" margin="auto" pt="50px" pb="70px">
        <Heading size="xl" mb="50px">
          Featured Artist
        </Heading>
        <Box
          display="flex"
          justifyContent={[
            "center",
            "center",
            "space-between",
            "space-between",
          ]}
          flexWrap="wrap"
        >
          {isLoading ? (
            <>
              {[4, 2, 8, 4, 4, 4, 4, 4].map((data) => (
                <Link to={data?.link} key={data?.name}>
                  <SkeletonCircle mb="80px" p="150px" />
                </Link>
              ))}
            </>
          ) : (
            data?.data?.featured_artists.map((data) => (
              <Link
                to={`/artist-details/${data?.user_id}`}
                key={data?.fullname}
              >
                <Box mb="60px" textAlign="center">
                  <Image
                    borderRadius="50%"
                    src={data?.picture}
                    alt="pheelz"
                    maxHeight="300px"
                    minHeight="300px"
                    maxWidth="300px"
                    minWidth="300px"
                  />

                  <Heading size="md" mt="20px">
                    {data?.fullname}
                  </Heading>
                  <Text fontSize="14px" mt="10px">
                    {data?.user_category}
                  </Text>
                </Box>
              </Link>
            ))
          )}
        </Box>
        <Link to="artist">
          <Button variant="primary"> View Artist</Button>
        </Link>
      </Box>
      <Box pt="90px" pb="171px">
        <Box m="10px">
          <Heading
            size={["xl", "2xl", "2xl", "2xl"]}
            textAlign="center"
            mt="20px"
          >
            Upcoming Events
          </Heading>
          <Text textAlign="center" mt="40px" mb="20px">
            We are excited to announce some amazing events that we have planned
            for the upcoming weeks.<br></br> Whether you are interested in
            networking or simply having fun, we have something for everyone.
            <br></br>
          </Text>
        </Box>
        <div
          ref={containerRef}
          className="hot"
          style={{
            overflowX: "scroll",
            whiteSpace: "nowrap",
          }}
        >
          <Box width="100%" justifyContent="center" display="flex">
            <Image
              src={generous}
              alt="pheelz"
              p="10px"
              mb="40px"
              height={["auto", "auto", "auto", "450px", "450px", "450px"]}
              width="auto"
              borderRadius="15px"
            />
          </Box>
        </div>
      </Box>
    </React.Fragment>
  );
}

export default Home;
