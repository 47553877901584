import { Box, Heading, Button, Text } from "@chakra-ui/react";
import { data } from "../../component/data/faqs";
import Faq from "react-faq-component";
import { RxPlusCircled } from "react-icons/rx";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

function RequestSongHome() {
  const config = {
    animate: true,
    expandIcon: <RxPlusCircled />,
    collapseIcon: <AiOutlineMinusCircle />,
  };
  return (
    <Box bg="white">
      <Box bg="backgroundColors" h="100px" borderBottom="1px solid white"></Box>
      <Box display="flex">
        <Box
          pl="50px"
          w={["100%", "100%", "50%", "50%"]}
          pb="180px"
          bg="backgroundColors"
          color="white"
        >
          <Heading size="lg" w="60%" mb="30px" mt="30px">
            Our songwriters create magic just for you!
          </Heading>
          <Text w="70%" mb="40px">
            Our artists will maintain a standard song structure of 2:00 - 2:30
            minutes in length which includes 2 verses and a chorus
          </Text>
          <Link to="/request1">
            <Button variant="primary">Request Song</Button>
          </Link>
        </Box>
        <Box
          display={["none", "none", "grid", "grid"]}
          backgroundImage="url('https://i.ibb.co/Vw2LGTK/Frame-8683.jpg')"
          backgroundRepeat="no-repeat"
          backgroundSize="auto"
          backgroundPosition={[
            "bottom right",
            "bottom right",
            "center",
            "center",
          ]}
          width="50%"
          borderBottomRightRadius="120px"
        ></Box>
      </Box>
      <Box m="auto" w="90%" mt="100px" pb="50px">
        <Faq data={data} config={config} />
      </Box>
    </Box>
  );
}

export default RequestSongHome;
