import {
  Box,
  Heading,
  Button,
  useToast,
  Spinner,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";

import React, { useState } from "react";
import { FormInput } from "../../component/inputs/inputs";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { loginUser } from "../../utils/fetch";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";

function Login() {
  const toast = useToast();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { mutate, isLoading } = useMutation(loginUser, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status === 200) {
        console.log(data);
        toast({
          position: "top-right",
          description: "Login successful",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        localStorage.setItem("token", JSON.stringify(data?.data?.token));
        localStorage.setItem("user_id", JSON.stringify(data?.data?.user_id));
        localStorage.setItem("usertype", JSON.stringify(data?.data?.usertype));
        localStorage.setItem("email", JSON.stringify(data?.data?.email));
        localStorage.setItem("fullname", JSON.stringify(data?.data?.fullname));
        window.setTimeout(() => {
          if (data?.data?.usertype === "admin") {
            window.location.href = `/admin/home`;
          } else {
            window.location.href = `/artist-details/${data?.data?.user_id}`;
          }
        }, 1000);
      } else if (data?.data?.status === 500) {
        toast({
          position: "top-right",
          description: data?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const registerArtist = (e) => {
    e.preventDefault();
    const data = { password: password, email: email };
    console.log(data);
    mutate({ ...data });
  };

  return (
    <>
      <Box margin="auto" w={["90%", "90%", "30%", "30%"]} mt="10px" mb="200px">
        <Heading size="md" textAlign="center" pt="180px" mb="40px">
          Industry Nite
        </Heading>
        <form onSubmit={registerArtist}>
          <FormInput label="Email" onChange={(e) => setEmail(e.target.value)} />

          <InputGroup size="md">
            <FormInput
              label="Password"
              type={show ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement
              width="4.5rem"
              mt="24px"
              onClick={() => setShow(!show)}
            >
              {show ? (
                <AiFillEye size="25px" />
              ) : (
                <AiFillEyeInvisible size="25px" />
              )}
            </InputRightElement>
          </InputGroup>
          <Button
            mt="-48px"
            ml="-13px"
            variant="transpare"
            fontSize="11px"
            fontWeight="100"
          >
            Forget password..?
          </Button>

          <Button
            type="submit"
            isDisabled={isLoading}
            variant="primary"
            w="100%"
            mb="40px"
          >
            {isLoading ? (
              <Spinner color="white" thickness="4px" size="lg" />
            ) : (
              "Login"
            )}
          </Button>
        </form>

        <Link to="/signup">
          <Button
            mt="-62px"
            ml="-13px"
            variant="transpare"
            fontSize="12px"
            fontWeight="400"
          >
            Create an Account
          </Button>
        </Link>
      </Box>
    </>
  );
}

export default Login;
