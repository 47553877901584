import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { Layout } from "./component/layout/layout";
import { DashboardLayout } from "./component/layout/dashboardLayout ";
import Home from "./pages/home/index";
import SignUp from "./pages/auth/signUp";
import Gallery from "./pages/galary";
import GalleryDetails from "./pages/galary/galarydetails";
import Artist from "./pages/artist/artist";
import ArtistDetails from "./pages/artist/artistDetails";
import NotFound from "./pages/notFound";
import Login from "./pages/auth/login";
import RequestSongHome from "./pages/requestSongs/index";
import RequestForm1 from "./pages/requestSongs/requestForm1";
import RequestForm2 from "./pages/requestSongs/requestForm2";
import RequestForm3 from "./pages/requestSongs/requestForm3";
import RequestSummary from "./pages/requestSongs/requestSummary";
import AdminHome from "./pages/dashboard";
import SongRequest from "./pages/dashboard/songRequest";
import UpdatePassword from "./pages/dashboard/updatePassword";
import DeliveredSongs from "./pages/dashboard/deliveredSongs";
import SongRequestSpec from "./pages/dashboard/songRequestSpec";
import BookArtist from "./pages/auth/bookArtist";
import Watch from "./pages/watch";
import AllArtistSong from "./pages/dashboard/allArtistData";
import AllArtistSongList from "./pages/dashboard/allArtistDataList";
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="gallery-details/:id" element={<GalleryDetails />} />
        <Route path="artist" element={<Artist />} />
        <Route path="artist-details/:user_id" element={<ArtistDetails />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="book-artist" element={<BookArtist />} />
        <Route path="watch" element={<Watch />} />
        <Route path="login" element={<Login />} />
        <Route path="request-song" element={<RequestSongHome />} />
        <Route path="request1" element={<RequestForm1 />} />
        <Route path="request2" element={<RequestForm2 />} />
        <Route path="request3" element={<RequestForm3 />} />
        <Route path="request-summary" element={<RequestSummary />} />
      </Route>
      <Route path="/admin" element={<DashboardLayout />}>
        <Route path="home" element={<AdminHome />} />
        <Route path="song-request" element={<SongRequest />} />
        <Route path="all-artist" element={<AllArtistSong />} />
        <Route
          path="all-artist-song-data/:user_id"
          element={<AllArtistSongList />}
        />
        <Route path="delivered-songs" element={<DeliveredSongs />} />
        <Route path="update-password" element={<UpdatePassword />} />
        <Route path="complete-song-request/:id" element={<SongRequestSpec />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
