import { Box, Button, Skeleton } from "@chakra-ui/react";
import { getGalaryDetails } from "../../utils/fetch";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ImageGallery from "react-image-gallery";

function GalleryDetails() {
  let { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, data } = useQuery(["get-galary-details", id], () =>
    getGalaryDetails(id)
  );
  const allPictures = data?.data?.results
    ? data?.data?.results?.map((data) => data.pic_url)
    : [];
  const arr = [];

  for (let i = 0; i < allPictures.length; i++) {
    arr.push({
      original: allPictures[i],
      thumbnail: allPictures[i],
    });
  }

  console.log(arr);
  return (
    <div>
      <Box borderBottom="1px solid white" w="100%" p="50px"></Box>
      <Button onClick={() => navigate(-1)} variant="transpare" m="10px">
        Back
      </Button>

      {isLoading ? (
        <Box m="auto" w="90%" mt="80px">
          <Skeleton mb="80px" w="100%" height="80vh" />
        </Box>
      ) : (
        <div style={{ padding: "40px" }}>
          <ImageGallery items={arr} />
        </div>
      )}
    </div>
  );
}

export default GalleryDetails;
