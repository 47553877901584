import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ScrollToTop from "../../utils/scrollToTop";
import {
  Box,
  Button,
  Spacer,
  Flex,
  IconButton,
  Heading,
  useDisclosure,
  DrawerOverlay,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { home } from "../../images/svg";

export const DashboardLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const logoutHandler = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };
  //"#b4816b"

  const fullname = JSON.parse(localStorage.getItem("fullname"));

  const navigate = useNavigate();
  const menuList = [
    { name: "DashBoard", link: "/admin/home" },
    { name: "Artist Data", link: "/admin/all-artist" },
    { name: "Song Request", link: "/admin/song-request" },
    { name: "Delivered songs", link: "/admin/delivered-songs" },
    { name: "Update password", link: "/admin/update-password" },
  ];
  return (
    <>
      <ScrollToTop />
      <Box>
        <Box color="white" width="100%" borderBottom="1px solid white">
          <Box pl={6} pr={6} pt={5} pb={7}>
            <Flex>
              <Link to="/admin/home">
                <Heading size="md" mt="-13px">
                  {home}
                </Heading>
              </Link>
              <Spacer />
              <Box
                justifyContent="space-between"
                mt="10px"
                display={["none", "none", "flex", "flex"]}
              >
                <Button variant="transpare" mr="40px">
                  Welcome {fullname}
                </Button>
                <Button
                  pl="30px"
                  pr="30px"
                  onClick={logoutHandler}
                  variant="primary"
                >
                  Logout
                </Button>
              </Box>
              <Box display={["flex", "flex", "none", "none"]} mt="10px">
                <Button
                  as={IconButton}
                  onClick={onOpen}
                  aria-label="Options"
                  icon={<HamburgerIcon />}
                  variant="primary"
                />
                <Drawer
                  isOpen={isOpen}
                  placement="right"
                  onClose={onClose}
                  finalFocusRef={btnRef}
                  size="xs"
                >
                  <DrawerOverlay />
                  <DrawerContent textAlign="left">
                    <DrawerCloseButton />
                    <Box mt="70px">
                      {menuList?.map((data) => (
                        <Box key={data?.name}>
                          <Button
                            variant="transpare"
                            onClick={() => {
                              navigate(data?.link);
                              onClose(true);
                            }}
                          >
                            {data?.name}
                          </Button>
                          <br></br>
                        </Box>
                      ))}
                    </Box>
                  </DrawerContent>
                </Drawer>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box display="flex">
          <Box
            w="250px"
            bg="#9095B5"
            pt="30px"
            display={["none", "none", "block", "block"]}
          >
            {menuList.map((item) => (
              <Button
                key={item?.link}
                bg={
                  window.location.pathname.includes(`${item?.link}`)
                    ? "white"
                    : "transparent"
                }
                w="100%"
                mb="20px"
                borderRadius={0}
                onClick={() => navigate(`${item?.link}`)}
                color={
                  window.location.pathname.includes(`${item?.link}`)
                    ? "black"
                    : "white"
                }
              >
                {item?.name}
              </Button>
            ))}
          </Box>
          <Box
            w="100%"
            p={["0px", "0px", "50px", "50px", "50px", "50px"]}
            h="85.5vh"
            overflowY="scroll"
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};
