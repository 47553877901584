import { extendTheme } from "@chakra-ui/react";
import { buttonStyle as Button } from "./buttonStyle";
import { mode } from "@chakra-ui/theme-tools";

const styles = {
  global: (props) => ({
    body: {
      // sets a custom bg color for dark mode only
      bg: mode(
        // light mode value retrieved from theme
        props.theme.semanticTokens.colors["chakra-body-bg"]._light,
        // your custom value for dark mode
        "linear-gradient(221.81deg, #1C1F33 15.38%, #0A0B15 84.11%);"
      )(props),
    },
  }),
};
export const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },

  fonts: {
    heading: `satoshi`,
    body: `satoshi`,
  },

  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                transform: "scale(0.85) translateY(-24px)",
                color: "#000000",
                borderRadius: "5px",
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
              {
                transform: "scale(0.85) translateY(-24px)",
                color: "#000000",
                borderRadius: "5px",
              },
            label: {
              color: "#C4C4C4",
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
    Button,
  },
  colors: {
    primary: "#EB4335",
    secondary: "#FFFFFF",
    backgroundColors:
      "linear-gradient(221.81deg, #1C1F33 15.38%, #0A0B15 84.11%)",
  },
  styles,
});
