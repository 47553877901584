import {
  Table,
  Thead,
  Tbody,
  Box,
  Tr,
  Th,
  Td,
  TableContainer,
  Skeleton,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getAllSongRequest } from "../../utils/fetch";
import { Link } from "react-router-dom";
import Pagination from "../../component/pagenation";
import { useEffect, useState } from "react";

function SongRequest() {
  const { isLoading, data } = useQuery(
    "get-all-song-request",
    getAllSongRequest
  );
  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(data?.data?.song_request?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.data?.song_request?.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, data]);

  console.log(currentItems);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % data?.data?.song_request?.length;

    setItemOffset(newOffset);
  };

  return (
    <Box>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Request ID</Th>
              <Th> Receiver's Email</Th>
              <Th> Order date</Th>
              <Th> Occasion</Th>
              <Th> Artist</Th>
              <Th> Price</Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <Tbody>
              {[9, 8, 9, 9, 0, 5]?.map((itemds) => (
                <Tr>
                  {[9, 8, 9, 9, 0, 5]?.map((itemds) => (
                    <Td>
                      <Skeleton h="30px" w />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Tbody>
              {currentItems?.reverse()?.map((itemds) => (
                <Tr key={itemds?.id}>
                  <Td>
                    <Link to={`/admin/complete-song-request/${itemds?.id}`}>
                      {itemds?.id}
                    </Link>
                  </Td>
                  <Td>
                    <Link to={`/admin/complete-song-request/${itemds?.id}`}>
                      {itemds?.email}
                    </Link>
                  </Td>
                  <Td>
                    <Link to={`/admin/complete-song-request/${itemds?.id}`}>
                      {itemds?.date}
                    </Link>
                  </Td>
                  <Td>
                    <Link to={`/admin/complete-song-request/${itemds?.id}`}>
                      {itemds?.ocassion}
                    </Link>
                  </Td>
                  <Td>
                    <Link to={`/admin/complete-song-request/${itemds?.id}`}>
                      {itemds?.artist_name}
                    </Link>
                  </Td>
                  <Td>
                    <Link to={`/admin/complete-song-request/${itemds?.id}`}>
                      {itemds?.price}
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Pagination
        pageCount={isNaN(pageCount) ? 0 : pageCount}
        handlePageClick={handlePageClick}
      />
    </Box>
  );
}

export default SongRequest;
