export const buttonStyle = {
  baseStyle: {},
  size: {},
  variants: {
    primary: {
      bg: "primary",
      color: "white",
      _hover: {
        bg: "transparent",
        color: "white",
        border: "1px solid white",
      },
    },
    secondary: {
      bg: "transparent",
      color: "white",
      border: "1px solid white",
      _hover: {
        bg: "primary",
        color: "white",
        border: "None",
      },
    },
    transpare: {
      bg: "transparent",
      color: "white",
      _hover: {
        bg: "transparent",
        color: "primary",
      },
    },
  },
  defaultProps: {},
};
