import { Box, Heading, Text, Flex, Skeleton } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getAllSongRequest } from "../../utils/fetch";

function AdminHome() {
  const { isLoading, data } = useQuery(
    "get-all-song-request",
    getAllSongRequest
  );

  return (
    <Box>
      <Heading size="md">Record</Heading>
      <Flex>
        {isLoading ? (
          <>
            <Skeleton h="100px" w="180px" m="50px" />
            <Skeleton h="100px" w="180px" m="50px" />
          </>
        ) : (
          <>
            <Box
              w="180px"
              color="black"
              bg="white"
              textAlign="center"
              p="10px 30px 10px 30px"
              fontWeight="800"
              m="50px"
            >
              <Text>New song Request</Text>
              <Text color="#1590B7" mt="10px">
                {data?.data?.new_song_request_no}
              </Text>
            </Box>
            <Box
              m="50px"
              w="180px"
              color="black"
              bg="white"
              textAlign="center"
              p="10px 30px 10px 30px"
              fontWeight="800"
            >
              <Text>Delivered song Request</Text>
              <Text color="#1590B7" mt="10px">
                {data?.data?.deleivered_song_request_no}
              </Text>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
}

export default AdminHome;
