import {
  Box,
  Heading,
  Button,
  useToast,
  Flex,
  Text,
  Skeleton,
} from "@chakra-ui/react";
import React, { useState } from "react";

import RequestFormContainer from "../../component/requestFormContainer";
import { getCutomizedFeaturedArtist } from "../../utils/fetch";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";

function RequestForm3() {
  const toast = useToast();
  const navigate = useNavigate();
  const savedGenre = JSON.parse(localStorage.getItem("form2"));
  const lengths = savedGenre?.length;

  const [artist, setArtist] = useState("");
  const genre = "";

  const { isLoading, data } = useQuery(
    ["get-all-cutomized-artist", genre, lengths],
    () => getCutomizedFeaturedArtist(genre, lengths)
  );
  console.log(data?.data);
  const submitArtist = () => {
    if (artist) {
      const result = data?.data?.results.find(
        ({ user_id }) => user_id === Number(artist)
      );
      const form3 = [{ price: data?.data?.song_price[0]?.price }, result];
      localStorage.setItem("form3", JSON.stringify(form3));
      navigate("/request-summary");
    } else {
      toast({
        position: "top-right",
        description: "Please select An artist",
        status: "error",
      });
    }
  };
  return (
    <RequestFormContainer number={2}>
      <Heading textAlign="center" size="md" m="20px">
        Select Artist
      </Heading>

      {isLoading ? (
        <Box
          display="flex"
          justifyContent={[
            "center",
            "space-between",
            "space-between",
            "space-between",
          ]}
          flexWrap="wrap"
        >
          <Skeleton mb="80px" w="204px" height="270px" />
          <Skeleton mb="80px" w="204px" height="270px" />
          <Skeleton mb="80px" w="204px" height="270px" />
          <Skeleton mb="80px" w="204px" height="270px" />
          <Skeleton mb="80px" w="204px" height="270px" />
          <Skeleton mb="80px" w="204px" height="270px" />
          <Skeleton mb="80px" w="204px" height="270px" />
          <Skeleton mb="80px" w="204px" height="270px" />
        </Box>
      ) : (
        <>
          {" "}
          <Box
            display="flex"
            justifyContent={[
              "center",
              "space-between",
              "space-between",
              "space-between",
            ]}
            flexWrap="wrap"
          >
            {data?.data?.results?.map((items) => (
              <label className="artistSide" key={items?.user_id}>
                <Box
                  w="204px"
                  h="270px"
                  mb="20px"
                  borderRadius="4px"
                  bg="white"
                  backgroundImage={items?.picture}
                  backgroundRepeat="no-repeat"
                  backgroundSize="contain"
                  backgroundPosition="center"
                >
                  <input
                    style={{
                      float: "right",
                      margin: "10px",
                      height: "18px",
                      width: "18px",
                    }}
                    type="radio"
                    name="songLength"
                    value={items?.user_id}
                    onChange={(e) => setArtist(e.target.value)}
                  ></input>
                  <Box pb="150px"></Box>
                  <Box
                    w="85%"
                    bg="white"
                    h="85px"
                    mt="35px"
                    color="black"
                    fontSize="12px"
                    p="5px"
                  >
                    <Box fontWeight="800">
                      <Text>Name: {items?.fullname.substring(-1, 30)}</Text>

                      <Text>Genre: {items?.genre}</Text>
                    </Box>
                    <Link
                      to={`/artist-details/${items?.user_id}`}
                      target="blank"
                    >
                      <Text textAlign="center" color="primary" mt="5px">
                        View Aritist
                      </Text>
                    </Link>
                  </Box>
                </Box>
              </label>
            ))}
          </Box>
          {data?.data?.results.length === 0 && (
            <Heading size="md" textAlign="center" mb="400px" mt="30px">
              Please select a different genre or show all artist
            </Heading>
          )}
        </>
      )}

      <Flex justifyContent="space-between">
        <Button onClick={() => navigate(-1)} variant="secondary">
          Back
        </Button>
        <Button onClick={submitArtist} variant="primary">
          Next
        </Button>
      </Flex>
    </RequestFormContainer>
  );
}

export default RequestForm3;
