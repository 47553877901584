import {
  Box,
  Heading,
  Button,
  Flex,
  Text,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { PaystackButton } from "react-paystack";
import { createCustonSong } from "../../utils/fetch";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

function RequestSummary() {
  const toast = useToast();
  const navigate = useNavigate();
  const form2 = JSON.parse(localStorage.getItem("form2"));
  const form1 = JSON.parse(localStorage.getItem("form1"));
  const form3 = JSON.parse(localStorage.getItem("form3"));

  const { mutate } = useMutation(createCustonSong, {
    onSuccess: (data) => {
      if (data?.data?.status === 200) {
        console.log(data);
        toast({
          position: "top-right",
          description: "Payment success check email for recipt",
          status: "success",
        });
        localStorage.removeItem("form2");
        localStorage.removeItem("form1");
        localStorage.removeItem("form3");
        window.location.href = `/`;
      } else {
      }
    },
    onError: () => {},
  });

  const componentProps = {
    email: form1?.email,
    amount: `${form3 && form3[0]?.price}00`,

    publicKey: "pk_test_a0dd079d7561d2e05b1a39feb563a7fe0518cf08",
    text: `Pay  ₦${form3 && form3[0]?.price}`,
    onSuccess: () => {
      const data = {
        ocassion: form1?.occasion,
        name: form1?.name,
        email: form1?.email,
        artist_name: form3[1]?.fullname,
        genre: form2?.genre,
        tempo: form2?.tempo,
        mood: form2?.mood,
        song_length: form2?.length,
        song_story: form2?.songInfo,
        price: form3[0]?.price,
      };
      console.log(data);

      mutate(data);
    },
    onClose: () =>
      toast({
        position: "top-right",
        description: "Please select the song length",
        status: "error",
      }),
  };
  const summaryContent = [
    { name: "Occasion", content: form1?.occasion },
    { name: "Name", content: form1?.name },
    { name: "Email", content: form1?.email },
    { name: "Artist Name", content: `${form3 && form3[1]?.fullname}` },
    { name: "Genre", content: form2?.genre },
    { name: "Tempo", content: form2?.tempo },
    { name: "Mood", content: form2?.mood },
    { name: "Song length", content: `${form2?.length} Min` },
  ];
  window.setTimeout(() => {
    if (!form1?.email || !form3[0]?.price) {
      toast({
        position: "top-right",
        description: "Please fill all required fields",
        status: "error",
      });
      window.location.href = `/request1`;
    }
  }, 5000);

  return (
    <>
      <Box h="100px" borderBottom="1px solid white"></Box>
      <Box
        w={["95%", "95%", "70%", "70%"]}
        m="auto"
        p={["10px", "10px", "80px", "80px"]}
        bg="#2D3748"
        borderRadius="10px"
        mt="50px"
        mb="30px"
      >
        <Heading size="md" textAlign="center" mt="20px" mb="20px">
          Preview
        </Heading>
        <Box m="auto" w="80%">
          {summaryContent?.map((items) => (
            <Box key={items?.name}>
              <Box display="flex" justifyContent="space-between">
                <Text>{items?.name}</Text>
                <Text>{items?.content}</Text>
              </Box>
              <Divider border="1px solid" mt="10px" mb="30px" />
            </Box>
          ))}
          <Text>
            Story
            <br></br>
            <br></br>
            {form2?.songInfo}
          </Text>
          <Flex justifyContent="space-between" mt="30px">
            <Button onClick={() => navigate(-1)} variant="secondary">
              Back
            </Button>

            <PaystackButton {...componentProps} className="paystack-button" />
          </Flex>
        </Box>
      </Box>
    </>
  );
}

export default RequestSummary;
