import {
  Box,
  Heading,
  Button,
  useToast,
  Text,
  Spinner,
  InputRightElement,
  InputGroup,
  Flex,
} from "@chakra-ui/react";

import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  FormInput,
  SelectInput,
  TextareaInput,
} from "../../component/inputs/inputs";
import * as Yup from "yup";
import {
  AiFillEyeInvisible,
  AiFillEye,
  AiFillInfoCircle,
} from "react-icons/ai";
import { registerUser } from "../../utils/fetch";
import { useMutation } from "react-query";
import { Tooltip } from "@chakra-ui/react";

const categoryData = [
  "Music Producer",
  "Independent Artist",
  "Signed Artist",
  "Song Writer",
];
const genderData = ["Male", "Female", "Non Binary"];

function SignUp() {
  const toast = useToast();

  const [image, setImage] = useState(null);
  const [picture, setPicture] = useState(null);
  const [linkMusic, setLinkMusic] = useState(false);
  const [show, setShow] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email(),
    fullname: Yup.string().required("Full Name is required"),
    age: Yup.string().required("Age is required"),
    gender: Yup.string().required("You must select an option!"),
    genre: Yup.string().required("You must add a genre!"),
    work_link: Yup.string().required("You musta work link"),
    user_category: Yup.string().required("You must select an option!"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/,
        "Must include one Number with Upper & Lowercase & a Special Character"
      ),

    biodata: Yup.string().required("Your bio is required"),
    city: Yup.string().required("Your city is required"),
    instagram: Yup.string().required("Your instagramgram link is required"),
  });

  const { mutate, isLoading } = useMutation(registerUser, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status === 200) {
        console.log(data);
        toast({
          position: "top-right",
          description: "We've created your account for you.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        window.setTimeout(() => {
          window.location.href = `/artist-details/${data?.data?.user_id}`;
        }, 1000);
      } else {
        toast({
          position: "top-right",
          description: `${data?.data?.error}`,
          status: "error",
          duration: 9000,
        });
      }
    },
    onError: () => {
      toast({
        position: "top-right",
        description: `Something went wrong`,
        status: "error",
        duration: 9000,
      });
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const registerArtist = (data) => {
    setLinkMusic(true);
    if (picture) {
      const formData = new FormData();
      formData.append("picture", picture);
      formData.append("email", data?.email);
      formData.append("password", data?.password);
      formData.append("fullname", data?.fullname);
      formData.append("age", data?.age);
      formData.append("gender", data?.gender);
      formData.append("work_link", data?.work_link);
      formData.append("user_category", data?.user_category);
      formData.append("biodata", data?.biodata);
      formData.append("city", data?.city);
      formData.append("genre", data?.genre);
      formData.append("instagram", data?.instagram);

      const AUDIO_LINK = data?.work_link; // Audio link here
      const API_KEY =
        "5f630c3878fb4fcea6e022fbfb732a44xStUmGCMMNiELvqDUmZvYluhvHlQRW"; // API key here

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic " + btoa(API_KEY + ":"));

      var raw = JSON.stringify({ link: AUDIO_LINK });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://api-us.musiio.com/api/v1/upload/audio-link",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.id) {
            formData.append("track_id", result?.id);
            setLinkMusic(false);
            console.log(result?.id);

            mutate(formData);
          } else {
            toast({
              position: "top-right",
              description: "please add a valid music link",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            setLinkMusic(false);
          }
        });
    } else {
      toast({
        position: "top-right",
        description: "please add a profile  picture",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
    setPicture(event.target.files[0]);
  };

  return (
    <>
      <Box margin="auto" w={["90%", "90%", "40%", "40%"]} mt="10px">
        <Heading size="md" textAlign="center" pt="120px">
          Welcome to Industry Nite
        </Heading>

        <form onSubmit={handleSubmit(registerArtist)}>
          <FormInput
            label="Email"
            error={register("email")}
            isInvalid={errors.email && errors.email.message}
          />

          <InputGroup size="md">
            <FormInput
              label="Password"
              type={show ? "text" : "password"}
              error={register("password")}
              isInvalid={errors.password && errors.password.message}
            />
            <InputRightElement
              width="4.5rem"
              mt="24px"
              onClick={() => setShow(!show)}
            >
              {show ? (
                <AiFillEye size="25px" />
              ) : (
                <AiFillEyeInvisible size="25px" />
              )}
            </InputRightElement>
          </InputGroup>
          <FormInput
            label="Full Name"
            error={register("fullname")}
            isInvalid={errors.fullname && errors.fullname.message}
          />
          <Box display="flex" justifyContent="space-between">
            <FormInput
              label="Age"
              type="number"
              error={register("age")}
              isInvalid={errors.age && errors.age.message}
              mr="30px"
            />

            <SelectInput
              options={genderData}
              error={register("gender")}
              label="Gender"
              isInvalid={errors.gender && errors.gender.message}
            />
          </Box>
          <FormInput
            label="What city do you reside in?"
            error={register("city")}
            isInvalid={errors.city && errors.city.message}
          />
          <FormInput
            label="Genre"
            error={register("genre")}
            isInvalid={errors.genre && errors.genre.message}
          />

          <Flex justifyContent="space-between">
            <FormInput
              label="Work Link"
              error={register("work_link")}
              isInvalid={errors.work_link && errors.work_link.message}
            />
            <Tooltip
              label="Only YouTube and SoundCloud Links Are Accepted"
              aria-label="A tooltip"
            >
              <Box mt="30px" ml="-40px" zIndex="10000">
                <AiFillInfoCircle size="25px" />
              </Box>
            </Tooltip>
          </Flex>

          <SelectInput
            options={categoryData}
            error={register("user_category")}
            label="You are?"
            isInvalid={errors.user_category && errors.user_category.message}
          />

          <FormInput
            label="Upload Instagram Name"
            error={register("instagram")}
            isInvalid={errors.instagram && errors.instagram.message}
          />
          <Text>Upload Picture</Text>
          {picture ? (
            <>
              <img alt="preview" src={image} style={{ maxHeight: "250px" }} />
              <Button
                variant="transpare"
                onClick={(e) => {
                  setImage(null);
                  setPicture(null);
                }}
              >
                remove pic
              </Button>
            </>
          ) : (
            <label
              style={{
                border: "none",
                borderRadius: "10px",
                background: "#222832",
                opacity: "0.7",
                width: "100%",
                display: "inline-block",
                padding: "6px 12px",
                cursor: "pointer",
                textAlign: "center",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
            >
              JPEG, JPG OR PNG.
              <input type="file" name="picture" onChange={onImageChange} />
            </label>
          )}

          <TextareaInput
            label="Bio"
            placeholder="Tell us a little about yourself"
            error={register("biodata")}
            isInvalid={errors.biodata && errors.biodata.message}
          />
          <Button
            type="submit"
            isDisabled={isLoading || linkMusic}
            variant="primary"
            w="100%"
            mb="40px"
          >
            {isLoading || linkMusic ? (
              <Spinner color="white" thickness="4px" size="lg" />
            ) : (
              "Submit"
            )}
          </Button>
        </form>
      </Box>
    </>
  );
}

export default SignUp;
