import { Box, Heading } from "@chakra-ui/react";
import NavBar from "../component/layout/navbar";
import Footer from "../component/layout/footer";
import { Notfound404 } from "../images/svg";

function NotFound() {
  return (
    <>
      <NavBar />
      <Box h="100vh" display="flex" justifyContent="center">
        <Heading mt="30vh">{Notfound404}</Heading>
      </Box>
      <Footer />
    </>
  );
}

export default NotFound;
