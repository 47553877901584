import {
  Box,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getArtist } from "../../utils/fetch";
import { useEffect, useState } from "react";
import Pagination from "../../component/pagenation";
import { Search2Icon } from "@chakra-ui/icons";

function Artist() {
  const { isLoading, data } = useQuery("get-artist", getArtist);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage] = useState(9);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    // const shuffeled = data?.data?.results?.sort(() => {
    //   const randomTrueOrFalse = Math.random() > 0.5;
    //   return randomTrueOrFalse ? 1 : -1;
    // });
    // console.log(shuffeled);
    setCurrentItems(data?.data?.results?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.data?.results?.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, data]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % data?.data?.results?.length;

    setItemOffset(newOffset);
  };
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    const newFilter = data?.data?.results?.filter((value) => {
      return value?.fullname.toLowerCase().includes(searchWord.toLowerCase());
    });
    console.log(filteredData);

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  return (
    <Box bg="linear-gradient(103.02deg, rgba(235, 67, 53, 0.03) 9.39%, #3E2432 47.61%, #EB4335 239.78%)">
      <Box pt="90px">
        <Heading
          size="lg"
          textAlign="center"
          margin="auto"
          w={["90%", "90%", "40%", "40%"]}
          mt="20px"
          mb="50px"
        >
          Listen to Our Artists
        </Heading>
        <Box w="80%" m="auto">
          <InputGroup w="350px" mt="10px" mb="30px">
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search"
              variant="outline"
              color="white"
              onChange={handleFilter}
              isDisabled={isLoading}
            />
          </InputGroup>
          {filteredData.length >= 1 && (
            <Box
              mt="5px"
              w="350px"
              height="300px"
              position="absolute"
              boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
              overflowY="scroll"
              bg="linear-gradient(221.81deg, #1C1F33 15.38%, #0A0B15 84.11%)"
              border="1px solid white"
              css={{
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "teal",
                  borderRadius: "24px",
                },
              }}
            >
              {filteredData &&
                filteredData.map((data) => (
                  <Link
                    key={data?.name}
                    to={`/artist-details/${data?.user_id}`}
                    passHref
                  >
                    <Box
                      w="100%"
                      display="flex"
                      pl="20px"
                      pt="10px"
                      pb="10px"
                      _hover={{ bg: "teal", cursor: "pointer" }}
                      _expanded={{ bg: "teal" }}
                      _focus={{ boxShadow: "teal" }}
                    >
                      <Image
                        mr="20px"
                        borderRadius="50%"
                        minHeight="30px"
                        maxHeight="30px"
                        minWidth="30px"
                        maxWidth="30px"
                        src={data?.picture}
                        alt={data?.artist_name}
                      />
                      {data?.fullname}
                    </Box>
                  </Link>
                ))}
            </Box>
          )}
        </Box>

        <Box
          w="80%"
          m="auto"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {isLoading ? (
            <>
              <Skeleton mb="80px" w="310px" height="400px" />
              <Skeleton mb="80px" w="310px" height="400px" />
              <Skeleton mb="80px" w="310px" height="400px" />
              <Skeleton mb="80px" w="310px" height="400px" />
              <Skeleton mb="80px" w="310px" height="400px" />
              <Skeleton mb="80px" w="310px" height="400px" />
            </>
          ) : (
            <>
              {currentItems?.map((data) => (
                <Box
                  w="310px"
                  display="grid"
                  bg="#1C1F33"
                  justifyContent="center"
                  mt="30px"
                  mb="50px"
                  key={data?.fullname}
                  borderRadius="10px"
                >
                  <Box display="flex" justifyContent="center">
                    <Image
                      mt="20px"
                      borderRadius="50%"
                      minHeight="210px"
                      maxHeight="210px"
                      minWidth="210px"
                      maxWidth="210px"
                      src={data?.picture}
                      alt="pheelz"
                    />
                  </Box>

                  <Box w="90%" m="auto">
                    <Heading mt="20px" size="sm">
                      {data?.fullname}
                    </Heading>
                    <Text mt="20px" mb="10px">
                      {data?.biodata.substring(-1, 115)}
                      {data?.biodata.length > 115 ? <>.....</> : ""}
                    </Text>
                    <Text
                      fontSize="13px"
                      textAlign="right"
                      textDecoration="underline"
                      mb="10px"
                    >
                      <Link to={`/artist-details/${data?.user_id}`}>
                        {" "}
                        View Artist
                      </Link>
                    </Text>
                  </Box>
                </Box>
              ))}
            </>
          )}
          <Pagination
            pageCount={isNaN(pageCount) ? 0 : pageCount}
            handlePageClick={handlePageClick}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Artist;
