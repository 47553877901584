import {
  Table,
  Thead,
  Tbody,
  Box,
  Tr,
  Th,
  Td,
  TableContainer,
  Skeleton,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getAllSongRequest } from "../../utils/fetch";
import Pagination from "../../component/pagenation";
import { useEffect, useState } from "react";

function DeliveredSongs() {
  const { isLoading, data } = useQuery(
    "get-all-song-request",
    getAllSongRequest
  );
  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(data?.data?.delivered_song?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.data?.delivered_song?.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, data]);

  console.log(currentItems);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % data?.data?.delivered_song?.length;

    setItemOffset(newOffset);
  };

  return (
    <Box>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Request ID</Th>
              <Th> Receiver's Email</Th>
              <Th> Order date</Th>
              <Th> Occasion</Th>
              <Th> Artist</Th>
              <Th> Price</Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <Tbody>
              {[2, 3, 4, 5, 6, 7, 8, 99, 0]?.map((itemds) => (
                <Tr key={itemds}>
                  {[2, 3, 4, 5, 6, 7, 8, 99, 0]?.map((itemds) => (
                    <Td key={itemds}>
                      <Skeleton h="30px" />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Tbody>
              {currentItems?.reverse()?.map((itemds) => (
                <Tr key={itemds?.id}>
                  <Td>{itemds?.id}</Td>
                  <Td>{itemds?.email}</Td>
                  <Td>{itemds?.date}</Td>
                  <Td>{itemds?.ocassion}</Td>
                  <Td>{itemds?.artist_name}</Td>
                  <Td>{itemds?.price}</Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Pagination
        pageCount={isNaN(pageCount) ? 0 : pageCount}
        handlePageClick={handlePageClick}
      />
    </Box>
  );
}

export default DeliveredSongs;
