import { Box } from "@chakra-ui/react";
import StepperComp from "./stepper";

function RequestFormContainer({ children, number }) {
  return (
    <Box pb="100px">
      <Box h="100px" borderBottom="1px solid white"></Box>
      <Box w={["90%", "90%", "50%", "50%"]} m="auto" mt="50px">
        <StepperComp number={number} />
      </Box>
      <Box
        w={["95%", "95%", "70%", "70%"]}
        m="auto"
        p={["10px", "10px", "80px", "80px"]}
        bg="#2D3748"
        borderRadius="10px"
        mt="50px"
      >
        {children}
      </Box>
    </Box>
  );
}

export default RequestFormContainer;
