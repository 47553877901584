import { Box, Heading } from "@chakra-ui/react";
import React, { useState } from "react";

export const HoverComponent = ({ image, city, wid, padder, height, m }) => {
  const [showText, setShowText] = useState(false);
  return (
    <Box
      onMouseEnter={(e) => setShowText(true)}
      onMouseLeave={(e) => setShowText(false)}
      backgroundImage={image}
      backgroundSize="cover"
      w={wid}
      height={height}
      m={m}
    >
      {showText ? (
        <Box
          fontSize="xl"
          textAlign="center"
          pt={padder}
          w={wid}
          height={height}
          opacity="0.5"
          backgroundColor="primary"
          color="white"
        >
          {city}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
